import { Injectable, ViewChild, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  BOT_TOKEN_INFO,
  USER_INFO,
  USER_TOKEN_INFO,
  IS_CUSTOM_LOGIN,
  IS_MSAL_LOGIN,
  Selected_Currency,
  USER_ID,
  IS_TURTLE_LOGIN,
} from '@algomusinc/domain';
import {
  AuthService,
  LocalstorageService,
  LoaderService,
  SnackbarService,
  ConfigurationService,
  NotificationService,
} from '@algomusinc/services';
import { environment } from '../environments/environment';
import { OktaAuthService } from '@algomusinc/okta';
import { SnackbarComponent } from '@algomusinc/components';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { jwtDecode } from 'jwt-decode';
import { POST_AUTH_TOKEN_INFO } from '@algomusinc/domain';
import moment from 'moment';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private oktaAuthService!: OktaAuthService;
  public methodsType: string[] = ['POST', 'PUT']
  constructor(
    public storage: LocalstorageService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public snackbar: SnackbarService,
    private loadingBar: LoadingBarService,
    //public config: ConfigurationService,
    public notif: NotificationService,
    private injector: Injector
  ) {
    if (environment.okta.enableOkta) {
      this.oktaAuthService = <OktaAuthService>(
        this.injector.get(OktaAuthService)
      );
    }
  }
  public hideLoaderList: string[] = [
    `${environment.AppFBSpreading}/SpreadingForecastAddCommitMeasures`,
    `${environment.FileManagementURL}/check-data-module-progress`
  ];
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let token: any = this.storage.get(USER_TOKEN_INFO);
    if (token && this.tokenExpired(token)) {
      this.askAgainSignIn();
    }
    const isCustomLogin = this.storage.get(IS_CUSTOM_LOGIN);
    const isMsalLogin = this.storage.get(IS_MSAL_LOGIN);
    const isTurtleLogin = this.storage.get(IS_TURTLE_LOGIN);
    if (isCustomLogin) {
      token = this.storage.get(USER_TOKEN_INFO);
    }
    else if(isMsalLogin)
    {
      token = this.storage.get(USER_TOKEN_INFO);
    }
    else if (this.oktaAuthService) {
      token = this.oktaAuthService.oktaAuth.getAccessToken();
    }
    else if(isTurtleLogin){
      token = this.storage.get(USER_TOKEN_INFO);
    }
    if (token) {
      if (request.url === environment.AppTokenGenerate) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + environment.BotSecretKey),
        });
      } else if (request.url.startsWith(environment.ClusterBaseUrl)) {
        const postAuthToken: string = this.storage.get(POST_AUTH_TOKEN_INFO);
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + postAuthToken )
        });
      } else {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token)});
      }
    }
    if(this.methodsType.includes(request.method)){
      request.body.modifiedById = this.storage.get(USER_ID)
    }
    if (
      !(
        request.url === `${environment.AppDocument}/UploadFiles` ||
        request.url === `${environment.AppFBForcast}/Import` ||
        request.url === `${environment.AppFBForcast}/ImportVerticalLayout` ||
        request.url === `${environment.ReplenishmentPOG}/ImportPOG` ||
        request.url === `${environment.ReplenishmentConfig}/UploadStorePriorityCSV` ||
        request.url === `${environment.AppItemManager}/UploadItemManagerCSVAsTable` ||
        request.url === `${environment.AccountHierarchy}/UploadAccountHierarchyCSVAsTable` ||
        request.url === `${environment.CalendarHierarchy}/UploadCalendarHierarchyCSVAsTable` ||
        request.url === `${environment.AppCommonCode}/DocumentAdd` ||
        request.url === `${environment.GenAiapi}/ask` ||
        request.url === `${environment.GenAiapi}/process_csv` ||
        request.url === `${environment.GenAiapi}/process_link` ||
        request.url === `${environment.GenAiapi}/get_col_description` ||
        request.url == `${environment.AppFBForcast}/ImportNew`
      )
    ) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/json'),
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });
      request = request.clone({
        headers: request.headers.set('x-application-key', 'AdminPortal'),
      });
      const currency = this.storage.get(Selected_Currency);
      if (currency) {
        request = request.clone({
          headers: request.headers.set('SelectedCurrencyId', currency.toString()),
        });
      }
    } else {
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });
    }
    if (isMsalLogin) {
      request = request.clone({
        headers: request.headers.set('UseTokenApi', 'true'),
      });
    } else {
      request = request.clone({
        headers: request.headers.set('UseTokenApi', 'false'),
      });
    }
    let currentTenant = this.storage.get('ACTIVE_CLIENT');
    if (currentTenant) {
      if ( request.url.startsWith(`${environment.AppTenant}`)) {
        currentTenant.name = environment.TENANT_KEY;
      }
      request = request.clone({
        headers: request.headers.set('TenantKey', currentTenant.name),
      });
    } else {
      request = request.clone({
        headers: request.headers.set('TenantKey', environment.TENANT_KEY),
      });
    }
    const self = this;
    const ref = this.loadingBar.useRef('http');
    if (this.hideLoaderList.includes(request.url)) {
      ref.complete();
    } else {
      ref.start();
    }
    return next.handle(request).pipe(
      finalize(() => {
        ref.complete();
      }),
      catchError(async (error: any): Promise<any> => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // this.askAgainSignIn();
            self.spinner.hide();
            return throwError(() => error);

          } else if (
            error &&
            error.error &&
            error.error.error &&
            error.error.error.indexOf('Multiple planner') > -1
          ) {
            this.snackbar.error(
              SnackbarComponent,
              'A new planner session has been opened.',
              'Multiple Planner Operations'
            );
            // this.config.updateForecastList.next(request);
          } else if (self.spinner) {
            self.spinner.hide();
          }
          return throwError(() => { ref.complete(); return error});
        }
      })
    );
  }
  askAgainSignIn() {
    // const isHomePage = this.storage.get('HOME_PAGE_HIDE');
    const naivigator = this.storage.get('naivigator');
    localStorage.clear();
    // this.storage.set('HOME_PAGE_HIDE', isHomePage);
    this.storage.set('naivigator', naivigator);
    window.location.reload();
  }
  private tokenExpired(token: string) {
    const expiry = jwtDecode(token).exp;
    const currentTimestamp = moment().valueOf();
    if (expiry) {
      return  currentTimestamp/1000 >= expiry;
    } else {
      return false;
    }
  }
}
