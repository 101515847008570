import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { API_URLS, environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@algomusinc/components';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpInterceptorService } from '../interceptors/http-interceptor.service';
import { OktaModule } from '@algomusinc/okta';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { EnvironmentService, ServiceModule } from '@algomusinc/services';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from '../common/GlobalErrorHandler';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { MsalModule } from '@azure/msal-angular';
import { routes } from './app.routes';
import { AppRoutingModule } from './app-routing.module';
import { VersionUpdateComponent } from './version-update/version-update.component';
import { Observable, tap } from 'rxjs';
const OKTA_DOMAIN = environment.okta.OKTA_DOMAIN; // `dev-30785815.okta.com`;//process.env.DOMAIN;
const CLIENT_ID = environment.okta.CLIENT_ID; // `0oa3dvvdjp3CbK5bg5d7`;//process.env.CLIENT_ID;
const CALLBACK_PATH = environment.okta.CALLBACK_PATH; // '/login/callback';

const AUTHORIZATION_SERVER = environment.okta.AUTHORIZATION_SERVER; // `/oauth2/default`; //`aus3p9jsstzSwf2g95d7`; //`default`;//
const PROTOCOL = environment.okta.PROTOCOL; // `https`;

const ISSUER = environment.okta.addAuthorizationServer
  ? `https://${OKTA_DOMAIN}/${AUTHORIZATION_SERVER}`
  : `https://${OKTA_DOMAIN}`;
const HOST = window.location.host;
const REDIRECT_URI = `${PROTOCOL}://${HOST}${CALLBACK_PATH}`;
const SCOPES = 'openid profile email offline_access';
// function initializeAppFactory(httpClient: HttpClient): () => Observable<any> {
//   // httpClient = new HttpClient(handler);
//   let headers = new HttpHeaders();
//   headers = headers.append('IsDaxQueryRequest', 'true');
//   headers = headers.append('Content-Type', 'application/json');
//   headers = headers.append('Accept', 'application/json');
//   let configAPIURL = localStorage.getItem('CONFIG_API');
//   if (configAPIURL) {
//     configAPIURL = JSON.parse(configAPIURL);
//   } else {
//     configAPIURL = API_URLS.configuration;
//   }
//   return () => httpClient.get(`${configAPIURL}/ClientConfigurations/GetList?ClientId=${API_URLS.ClientId}`,{headers: headers})
//     .pipe(
//        tap((config) => { 
//         if (config && (config as Array<any>).length) {
//           (config as Array<any>).forEach(c => {
//             c.keyValue = isNaN(Number(c.keyValue)) ? c.keyValue: Number(c.keyValue);
//             EnvironmentService.environment[c.keyName] = c.keyValue == 0 ? false: c.keyValue == 1 ? true: c.keyValue
//           });
//         }
//       }
//       )
//     );
//  }


let okta: any[] = [];
if (environment.okta.enableOkta) {
  const config: OktaAuthOptions = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    pkce: true,
    redirectUri: REDIRECT_URI,
    scopes: SCOPES.split(/\s+/),
  };

  const oktaAuth = new OktaAuth(config);
  okta = [OktaModule.forRoot(oktaAuth, config)];
}
@NgModule({
  declarations: [	AppComponent, VersionUpdateComponent
   ],
  imports: [
    ServiceModule.forRoot(environment),
    BrowserModule,
    BrowserAnimationsModule,
    // routes,
    SharedModule,
    AppRoutingModule,
    // for Core use:
    LoadingBarModule,
    ServiceWorkerModule.register('service-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:10000',
    }),
    BrowserAnimationsModule,
    ...okta,
    MsalModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeAppFactory,
    //   deps: [HttpClient, HttpBackend],
    //   multi: true
    // }
    // {
    //   // processes all errors
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
